import { Button, Card, Icon, Title } from "components/common";
import { ADD_AP, ADD_EXPLANATION, ADD_SCHEMA } from "components/svg";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBasicScriptData } from "store/scriptSlice";
import theme from "utils/themeVars";
import styles from "../index.module.less";
import ScriptExplanationCollapseCards from "../scriptExplanationCollapseCards";
import AssessmentAndPlanAddEditForm from "./AssesmentAndPlans";
import SchemaModal from "./schemaModal";
import ImageSlider from "components/common/slider/slider";
import { MdOutlinePodcasts } from "react-icons/md";
import PodCastModal from "./podcastModal";
import { canUserModify } from "features/presentation/utils";

const ScriptExplanationSection = ({
  explanations,
  schemas,
  assessments,
  onEditSave,
  editMode,
  updateLoading,
  isMobile,
  user,
  podCast,
  data,
  onPodCast,
  handleChange,
  showSchemas = true,
  showAssessments = true,
  showPodCast = true,
  initialData,
}) => {
  const [activeKeys, setActiveKeys] = useState(["0"]);
  const [selectedSchema, setSelectedSchema] = useState(null);
  const [podCastModal, setPodCastModal] = useState(null);
  const [podCastData, setPodCastData] = useState(null);

  const handlePodCastData = (data) => {
    setPodCastData(data);
    onPodCast(data);
  };
  useEffect(() => {
    if (explanations?.length) {
      setActiveKeys(explanations.map((e, i) => i.toString()));
    }
  }, [explanations]);

  const handleAddExplanation = useCallback(() => {
    const tempExplanations = JSON.parse(JSON.stringify(explanations));
    tempExplanations?.push({
      key: tempExplanations?.length?.toString(),
      description: "",
      title: "",
      media: [],
    });
    handleChange("explanations", tempExplanations);
  }, [explanations]);

  let explanationActionList = [
    ...(canUserModify(user, initialData?.editor_id)
      ? [
          {
            key: "action1",
            text: "Add Explanation",
            icon: isMobile ? <></> : <ADD_EXPLANATION />,
            onClick: handleAddExplanation,
          },
        ]
      : []),
    ...(showSchemas
      ? [
          {
            key: "action2",
            text: "Add Schema",
            icon: isMobile ? <></> : <ADD_SCHEMA />,
            onClick: () =>
              setSelectedSchema({
                title: "",
                type: "schema",
                data: {
                  nodes: [],
                  edges: [],
                },
              }),
          },
        ]
      : []),
    ...(showAssessments && !assessments?.length
      ? [
          {
            key: "action3",
            text: "Add A/P",
            icon: isMobile ? <></> : <ADD_AP />,
            onClick: () => {
              const tempAssessments = JSON.parse(JSON.stringify(assessments));
              tempAssessments?.push({
                key: tempAssessments?.length?.toString(),
                description: "",
                pinned: false,
                pinnedBy: [],
                createdBy: user?._id,
                userName: user?.firstname + " " + user?.lastname,
              });
              handleChange("assessmentAndPlans", tempAssessments);
            },
          },
        ]
      : []),
    {
      key: "action4",
      text: "Add Podcast",
      icon: isMobile ? <></> : <MdOutlinePodcasts />,
      onClick: () => {
        setPodCastModal(true);
      },
    },
  ];

  const renderExplanationActions = () => {
    return (
      <>
        {explanationActionList?.map((e) => (
          <Button
            text={e?.text}
            type="bordered"
            style={{
              borderColor: theme["blue100"],
              color: theme["blue200"],
              background: theme["light-gray"],
            }}
            icon={e?.icon}
            iconProps={{ size: "16px" }}
            size="small"
            onClick={e?.onClick}
          />
        ))}
      </>
    );
  };

  const renderHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1%",
        }}
      >
        <Title level={2}>Explanation</Title>
        <Button
          text={
            activeKeys?.length == explanations?.length
              ? "Contract All"
              : "Expand All"
          }
          type="bordered"
          style={{
            borderColor: theme["blue100"],
            color: theme["blue200"],
            background: theme["light-gray"],
          }}
          icon={
            activeKeys?.length == explanations?.length
              ? "BsArrowsCollapse"
              : "BsArrowsExpand"
          }
          iconProps={{ size: "16px" }}
          size="small"
          onClick={() =>
            activeKeys?.length == explanations?.length
              ? setActiveKeys([])
              : setActiveKeys(explanations?.map((e, i) => i.toString()))
          }
        />
      </div>
    );
  };

  const handleRemoveSchema = (index) => {
    const tempSchemas = JSON.parse(JSON.stringify(schemas));
    handleChange(
      "schemas",
      tempSchemas.filter((e, ind) => ind != index)
    );
  };

  const renderSchemas = useCallback(() => {
    return schemas?.length ? (
      <Card
        style={{ marginBottom: "10px" }}
        id={`${explanations?.length + 3}-explanation-collapse-card`} //Do not remove this id as this represents schema section to scroll from left card
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
          }}
          className="pointer"
        >
          {schemas.map((e, index) => (
            <div
              className={styles.schemaTile}
              onClick={() => setSelectedSchema(e)}
            >
              <Icon size={24} icon="MdOutlineSchema" />
              <div style={{ fontSize: "20px" }}>{`Schema - ${index + 1}`}</div>
              <Icon
                size={10}
                icon="ImCross"
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveSchema(index);
                }}
              />
            </div>
          ))}
        </div>
      </Card>
    ) : null;
  }, [schemas]);

  const handleAddSchema = (newSchema) => {
    const tempSchemas = JSON.parse(JSON.stringify(schemas));
    tempSchemas.push({ title: newSchema?.title, data: newSchema?.data });
    handleChange("schemas", tempSchemas);
  };

  const handleAddPodcast = (newPodcast) => {
    const tempPodCasts = JSON.parse(JSON.stringify(podCast));
    tempPodCasts.push(newPodcast);
    handleChange("podCast", tempPodCasts);
  };

  return (
    <>
      <Card style={{ marginBottom: "10px" }}>
        {renderHeader()}
        <ScriptExplanationCollapseCards
          explanations={explanations}
          handleChange={handleChange}
          onEditSave={onEditSave}
          activeKeys={activeKeys}
          setActiveKeys={setActiveKeys}
          editMode={editMode}
          data={data}
          initialData={initialData}
        />
        {showAssessments && assessments?.length ? (
          <AssessmentAndPlanAddEditForm
            assessments={assessments}
            user={user}
            handleChange={handleChange}
            activeKeys={activeKeys}
            setActiveKeys={setActiveKeys}
            panelKey={`${explanations?.length + 1}-explanation-collapse-card`}
          />
        ) : null}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "1%",
            paddingTop: "2%",
          }}
        >
          {renderExplanationActions()}
        </div>
      </Card>

      {/* PODCAST UI */}
      {showPodCast && (
        <Card id={`${explanations?.length + 2}-explanation-collapse-card`}>
          <ScriptExplanationCollapseCards
            isPodCast={true}
            podCast={podCast}
            handleChange={handleChange}
            onEditSave={onEditSave}
            activeKeys={activeKeys}
            setActiveKeys={setActiveKeys}
            editMode={editMode}
            data={data}
            onPodCastData={handlePodCastData}
          />
        </Card>
      )}

      {showSchemas && renderSchemas()}
      <PodCastModal
        visible={podCastModal}
        setVisible={setPodCastModal}
        onConfirm={(e) => handleAddPodcast(e)}
      />
      <SchemaModal
        onConfirm={handleAddSchema}
        visible={selectedSchema}
        setIsVisible={setSelectedSchema}
        schemaData={selectedSchema}
        destroyOnClose
        onCancel={() => {
          setSelectedSchema(null);
        }}
      />
    </>
  );
};

export default ScriptExplanationSection;
