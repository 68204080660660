import { Card } from "antd";
import { Divider, Icon, Title } from "components/common";
import { useGetQuizDetailsQuery } from "features/script/api";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { setQuizResultData } from "store/quizSlice";
import theme from "utils/themeVars";
import QuestionItem from "../takeQuiz/questionItem";
import { SCRIPT_QUIZ_LISTING } from "constants/app-paths";

const QuizResult = () => {
  const params = useParams();
  const quizDetails = useSelector((state) => state?.quiz?.data?.result);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const quizId = params?.id;
  const { data } = useGetQuizDetailsQuery(
    { quizId },
    { refetchOnMountOrArgChange: true, skip: !quizId }
  );

  useEffect(() => {
    if (data?.length) {
      dispatch(setQuizResultData(data?.[0]));
    }
  }, [data]);

  return (
    <div style={{ minHeight: "100vh", backgroundColor: theme["light-gray"] }}>
      <Card>
        <div style={{ margin: "1%" }}>
          <div
            style={{
              display: "flex",
              gap: "20px",
              alignItems: "center",
              marginLeft: "20px",
            }}
          >
            <Icon
              icon="IoMdArrowBack"
              size={24}
              onClick={() => {
                navigate(SCRIPT_QUIZ_LISTING);
              }}
              color={theme["primary-color"]}
              className="pointer"
            />
            <Title style={{ marginBottom: "0px" }} level={1}>
              Result
            </Title>
          </div>
          {quizDetails?.questionsList?.map((question, i) => (
            <div style={{ marginBottom: "30px" }}>
              <QuestionItem
                question={question}
                selectedQuestion={i}
                quizDetails={quizDetails}
                showQuizActions={false}
                resultPage={true}
              />
              {i != quizDetails?.questionsList?.length - 1 && <Divider />}
            </div>
          ))}
        </div>
      </Card>
    </div>
  );
};

export default QuizResult;
